import React from "react";
import "twin.macro";
import { graphql } from "gatsby";
import { PageLayout, CTA } from "../components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const OCT = ({
  data: {
    strapiDsrOct: { octCards, ...pageProps },
  },
}) => {
  return (
    <PageLayout {...pageProps} flourish={1}>
      <div tw="px-4 relative sm:px-6 lg:px-8 bg-gray-50">
        <div tw="mt-6 prose prose-lg text-gray-500 mx-auto">
          <section tw="py-8 text-gray-500">
            <div tw="mx-auto px-4">
              <div tw="flex flex-wrap -mx-4 space-y-6">
                {octCards.map(({ header, description, image }) => (
                  <div
                    key={image.id}
                    tw="bg-white p-4 rounded-lg shadow-lg w-full"
                  >
                    <div tw="flex flex-wrap -mx-4 space-y-4 md:space-y-0 items-center">
                      <div tw="px-4 w-full md:w-4/12">
                        <GatsbyImage
                          image={getImage(image.localFile)}
                          tw="max-w-full w-full transition-colors duration-200 hover:opacity-75 rounded-lg"
                          imgStyle={{ marginTop: 0, borderRadius: "0.5rem" }}
                          alt={image.alternativeText}
                        />
                      </div>
                      <div tw="px-4 w-full md:w-8/12">
                        <h5 tw="font-bold leading-tight text-xl block mb-2 text-gray-800">
                          {header}
                        </h5>
                        <p>{description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
      <CTA />
    </PageLayout>
  );
};

export default OCT;

export const query = graphql`
  query octPageQuery {
    strapiDsrOct {
      subtitle
      title
      intro
      content
      octCards {
        description
        header
        image {
          id
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 350)
            }
          }
        }
      }
    }
  }
`;
